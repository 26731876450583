/*
 * Check required variables
 * */
function assertRequired(value: unknown, variableName: string) {
  if (value === undefined) {
    throw Error(`Env variable ${variableName} is required`)
  }
}

/* Версия приложения из package.json */
export const APP_VERSION = import.meta.env.APP_VERSION

/* API entrypoint */
export const API_URL = import.meta.env.VITE_API_URL
assertRequired(API_URL, 'VITE_API_URL')

/* Режим разработки */
export const isDevEnv = import.meta.env.DEV
/* Режим продакшена */
export const isProdEnv = import.meta.env.PROD

/* End-to-end тестирование */
export const isE2eTest = import.meta.env.VITE_E2E_TEST === 'true'

/* Логирование */
export const SENTRY_DSN = import.meta.env.SENTRY_DSN

export const isTestStand = import.meta.env.STAND === 'TEST'
export const isDemoStand = import.meta.env.STAND === 'DEMO'
export const isProdStand = import.meta.env.STAND === 'PROD'

export const STAND_PREFIX = isProdStand ? '' : isDemoStand ? 'uat.' : 'test.'

export const NAVIGATION_DOMAIN = `https://${STAND_PREFIX}apark.pro/`
export const TMS_DOMAIN = `https://tms.${STAND_PREFIX}apark.pro/`
